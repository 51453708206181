<!-- 对象通用列表页 -->
<template>
  <div class="chooseView">
    <svg class="icon pull-left viewImg" aria-hidden="true">
      <use
        :href="
          tabStyle
            ? '#icon-' + tabStyle
            : targetImg
            ? '#icon-' + targetImg
            : '#icon-hometab'
        "
      ></use>
    </svg>
    <span class="el-dropdown-link viewName">
      <span class="tit">{{ objectName }}</span>
      <br />
      <el-dropdown @command="handleCommand" trigger="hover">
        <div class="title">
          <span style="display: none">{{ viewId }}</span>
          <span>{{ viewName }}</span>
          <i class="el-icon-caret-bottom" style="margin-left: 3px"></i>
        </div>

        <el-dropdown-menu slot="dropdown">
          <div class="drop_box">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-sousuo"></use>
            </svg>
            <!-- 搜索视图 -->
            <el-input
              :placeholder="$t('vue_label_commonobjects_view_searchview')"
              class="left_inp"
              v-model="keyWord"
            ></el-input>
          </div>
          <vuedraggable v-model="dataList">
            <el-dropdown-item
              v-for="(val, i) in dataList"
              :key="i"
              :class="{ active: val.isCurrent }"
              :command="val"
              style="display: flex"
            >
              <div v-if="val.name">
                <strong style="display: none; font-weight: normal">{{
                  val.id
                }}</strong>
                <strong style="font-weight: normal">{{ val.name }}</strong>
              </div>
              <div v-else>
                <strong style="display: none; font-weight: normal">{{
                  val.id
                }}</strong>
                <strong style="font-weight: normal">{{ val.label }}</strong>
              </div>
              <!-- 默认视图-->
              <span v-if="!targetFlag">
                {{
                  val.isdefault == 1
                    ? "(" + $t("label.view.sortview.default") + ")"
                    : ""
                }}
              </span>
            </el-dropdown-item>
          </vuedraggable>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 要取消当前默认视图的设置，可将其他视图设为默认视图 -->
      <div class="thumbtack" v-if="!targetFlag">
        <div class="tubiao" v-if="this.thumbtackImg === this.thumbtack">
          <svg class="icon inline_block" aria-hidden="true" @click="defaultBtn">
            <use :href="'#icon-' + thumbtackImg"></use>
          </svg>
        </div>
        <el-tooltip
          v-else
          popper-class="my-tooltip"
          :content="$t('vue_label_commonobjects_view_currentviewset')"
          placement="top-start"
        >
          <div class="tubiao">
            <svg
              class="icon inline_block"
              aria-hidden="true"
              @click="defaultBtn"
            >
              <use :href="'#icon-' + checkedthumbtack"></use>
            </svg>
          </div>
        </el-tooltip>
      </div>
    </span>
  </div>
</template>

<script>
/**
 * @description 视图列表
 * 功能：
 * 1、展示当前、对象图标和当前视图
 * 2、视图搜索
 * 3、切换视图
 * 4、设置默认视图
 * 逻辑：
 * 1、根据传入的数据展示展示当前、对象图标和当前视图
 * 2、根据输入的关键字,模糊搜索并展示符合条件的视图搜索结果
 * 3、点击视图列表中任一数据,可切换视图,并可拖拽改变视图展示顺序
 * 4、点击视图列表右侧按钮，将当前视图设置为默认视图
 */
import vuedraggable from "vuedraggable";
import { SetViewDefault } from "./api.js";

export default {
  components: {
    vuedraggable,
  },
  props: {
    /**
     * 视图所在对象名称
     */
    objectName: {
      type: String,
      default() {
        return this.$i18n.t("component_setup_label_object");
      },
    },
    /**
     * 当前视图名称
     */
    viewName: {
      type: String,
      default: "",
    },
    /**
     * 视图id
     */
    viewId: {
      type: String,
      default: "",
    },
    /**
     * 视图列表
     */
    viewList: {
      type: Array,
      default: ()=>[],
    },
    /**
     * 视图所在对象id
     */
    objId: {
      type: String,
      default: "",
    },
    /**
     * 视图所在对象前缀
     */
    prefix: {
      type: String,
      default: "",
    },
    /**
     * 视图所在对象的图标
     */
    tabStyle: {
      type: String,
      default: "",
    },
    /**
     * 是否是默认视图
     */
    targetFlag: {
      type: Boolean,
      default: false,
    },
    /**
     * 视图所在对象的图标
     */
    targetImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      thumbtack: "thumbtack", // 非默认视图图标标识的名称
      checkedthumbtack: "thumbtack1", // 默认视图图标标识的名称
      defaultViewId: "", // 默认视图id
      keyWord: "", // 搜索视图关键字
      dataList: this.viewList, // 视图列表数据
      thumbtackImg: this.thumbtack, // 默认视图图标标识的名称
    };
  },
  mounted() {
    this.initJudgeDefalutView();
  },
  methods: {
    /**
     * 初始化判断是否为默认视图
     */
    initJudgeDefalutView() {
      this.$parent.business && this.$parent.business();
      this.dataList.forEach((val) => {
        //如果它有视图id，选中视图id
        if (this.viewId && val.id === this.viewId && val.isdefault === "1") {
          val.isCurrent = true;
          this.thumbtackImg = this.checkedthumbtack;
        } else if (
          this.viewId &&
          val.id === this.viewId &&
          val.isdefault !== "1"
        ) {
          val.isCurrent = true;
          this.thumbtackImg = this.thumbtack;
        }
        //如果它没有视图id，默认选中默认视图
        if (!this.viewId) {
          if (val.isdefault === "1") {
            this.defaultViewId = val.id;
            val.isCurrent = true;
            this.thumbtackImg = this.checkedthumbtack;
          } else {
            this.thumbtackImg = this.thumbtack;
          }
        }
      });
    },
    /**
     * 切换视图
     * @param {command} 即将切换到的视图数据
     */
    handleCommand(command) {
      this.dataList.forEach((val) => {
        if (val.isdefault === "1") {
          this.defaultViewId = val.id;
        }
      });
      if (this.$router.apps[0]._route.params.type === "kanban") {
        let rs = this.$router.apps[0]._route.params;
        this.$router.push({
          path:
            `/commonObjects/views/kanban/${rs.objectApi}/${rs.objId}/${rs.prefix}` +
            "?viewId=" +
            command.id,
        });
      }
      this.keyWord = "";
      if (command.id === this.defaultViewId) {
        this.thumbtackImg = this.checkedthumbtack;
      } else {
        this.thumbtackImg = this.thumbtack;
      }
      this.$emit("changeView", command);
    },
    /**
     * 将当前视图设置默认视图
     */
    defaultBtn() {
      if (this.thumbtackImg !== this.checkedthumbtack) {
        this.thumbtackImg = this.checkedthumbtack;
        this.dataList.forEach((item) => {
          if (item.id === this.viewId) {
            this.defaultViewId = item.id;
          }
        });
        let data = {
          objId: this.objId,
          defaultViewId: this.defaultViewId,
        };
        SetViewDefault(data).then((res) => {
          if (res.result === true) {
            this.$message({
              showClose: true,
              type: "success",
              //   设置默认视图成功
              message: this.$i18n.t(
                "vue_label_commonobjects_view_successfully"
              ),
            });
            this.$emit("getViewList", false);
          } else {
            this.$message({
              showClose: true,
              type: "error",
              //   设置默认视图失败
              message: this.$i18n.t("vue_label_commonobjects_view_fail"),
            });
          }
        });
      }
    },
  },
  watch: {
    /**
     * 监听搜索视图关键字变化,对视图列表进行过滤
     */
    keyWord(nval) {
      this.dataList = this.dataList.filter((val) => {
        return (
          val.label && val.label.toLowerCase().indexOf(nval.toLowerCase()) != -1
        );
      });
      if (nval == "") {
        this.dataList = this.viewList;
      }
    },
    /**
     * 监听父组件视图列表数据变化,对页面数据进行处理
     */
    viewList(nval) {
      this.dataList = nval;
      this.initJudgeDefalutView();
    },
  },
};
</script>

<style lang="scss" scoped>
.chooseView {
  position: relative;
  display: flex;
  .thumbtack {
    position: absolute;
    top: 22px;
    cursor: pointer;
    // width: 180px;
    // height: 35px;
    border-radius: 2px;
    text-align: center;
    display: inline-block;
    .View-seas {
      margin-top: -3px;
      text-align: left;
    }
    .tubiao {
      position: absolute;
      bottom: 0;
      left: 0px;
      background: #fff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      width: 18px;
      height: 18px;
      top: 0;
      svg {
        vertical-align: 0 !important;
        margin-left: 1px;
        margin-top: 1px;
        width: 13px;
      }
    }
  }

  .viewImg {
    margin-right: 10px;
    margin-top: 2px;
    width: 37px;
    height: 40px;
  }

  .viewName {
    cursor: pointer;
    font-weight: 400;
    color: #333333;
    line-height: 1.3;
    height: 30px;
    .tit {
      font-size: 12px;
    }
    .seas_style {
      font-size: 12px;
      padding-left: 10px;
    }
    .title {
      margin-right: 6px;
      font-size: 18px;
      color: #080707;
      font-weight: 600;
      overflow: hidden;
      max-width: 600px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        color: #677079;
      }
      i {
        color: #888888;
      }
    }
  }

  .icon_s {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
  }
}
</style>
<style lang="scss" scoped>
.el-dropdown {
  display: inline;
}
.el-popper {
  border: 0;
}
.el-dropdown-menu {
  max-height: 500px;
  min-height: 160px;
  margin: 0;
  width: 418px;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  left: 65px !important;
  padding-top: 0;
  ::v-deep input::-webkit-input-placeholder {
    color: #706e6b;
  }
  .left_inp {
    width: 85%;
    ::v-deep .el-input__inner {
      padding-left: 0;
      border: 0;
      background: none;
      outline: none;
      border: none;
      font-size: 14px;
    }
  }

  li {
    font-size: 14px;
    width: 425px;
    color: #3e3e3c !important;
  }
  li:hover {
    background: #f5f5f5 !important;
    color: #006dcc !important;
  }
  li.active {
    background: rgb(0, 109, 204) !important;
    color: #fff !important;
  }
  .drop_box {
    border-bottom: 1px solid #ccc;
    height: 40px;
    margin-bottom: 4px;
    svg {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: -3px 10px 0 21px;
    }
  }
}
</style>
